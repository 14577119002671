<template>
  <div>
    <FilterOverview title="Reiseterminplanung" :showFilterInitially="false" @reload="onReload">
      <template #toolbar-left>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          style="gap: 12px"
        >
          <RegionFilterButtonGroup
            :regions="['EU', 'FERN', 'DACH']"
            :disabled="regionFilterDisabled"
            v-model="selectedRegions"
            width="150px"
          ></RegionFilterButtonGroup>

          <b-form-checkbox v-model="vergangeneTermineAusblenden" @change="gridApi.onFilterChanged()" switch>
            Vergangene Termine ausblenden
          </b-form-checkbox>
          <b-button size="sm ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
            Alle Filter zurücksetzen
          </b-button>
        </div>
      </template>
      <template #toolbar-right>
        <b-button size="sm" variant="primary" v-b-modal.modalReiseterminAnlegen class="mr-2">
          + Termin anlegen
        </b-button>
        <b-button
          size="sm"
          class="mr-2"
          v-b-modal.modalMultipleReiseterminBearbeiten
          v-if="allSelectedRows.length > 1 && onlyOneReiseSelected"
        >
          Daten bearbeiten
        </b-button>

        <b-dropdown right text="..." no-caret size="sm" class="mr-3">
          <template #button-content>
            <div class="dots-dropdown">...</div>
          </template>
          <b-dropdown-item
            href="#"
            @click="openModalStatusBearbeiten"
            v-if="
              allSelectedRows.length > 0 &&
              onlyOneReiseSelected &&
              isOnlyOneReiseterminStateSelected &&
              ['Vorgeschlagen', 'Verworfen', 'CmBestaetigt', 'CmAngefragt'].includes(
                stateSelectedReisetermine
              )
            "
          >
            Status bearbeiten
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="openAufgabenModalWithData">Aufgabe erstellen</b-dropdown-item>
          <b-dropdown-item
            size="sm"
            @click="onClickDeleteReisetermine"
            variant="danger"
            v-if="
              allSelectedRows.length > 0 &&
              allSelectedRows.every(item => item.data.reiseterminSyncState == 'NotAvailableInBlank')
            "
          >
            Reisetermine löschen
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="d-flex align-items-center" style="gap: 8px">
              <div>Export</div>

              <div class="d-flex align-items-center">
                <div @click="onButtonExport('de')" class="symbol symbol-20">
                  <img src="media/svg/flags/162-germany.svg" class="flagExport" alt="germanFlag" />
                </div>
                <div @click="onButtonExport('en')" class="symbol symbol-20 ml-2">
                  <img src="media/svg/flags/226-united-states.svg" class="flagExport" alt="usFlag" />
                </div>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="d-flex align-items-center" style="gap: 2px">
              <div class="mr-2">Extern</div>

              <div class="d-flex align-items-center">
                <div @click="onButtonExportExtern('de')" class="symbol symbol-20">
                  <img src="media/svg/flags/162-germany.svg" class="flagExport" alt="germanFlag" />
                </div>
                <div @click="onButtonExportExtern('en')" class="symbol symbol-20 ml-2">
                  <img src="media/svg/flags/226-united-states.svg" class="flagExport" alt="usFlag" />
                </div>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          rowHeight="50px"
          class="ag-theme-alpine m-0 p-0"
          @grid-ready="onGridReady"
          :rowModelType="'serverSide'"
          :statusBar="statusBar"
          :columnDefs="columnDefs"
          :tooltipShowDelay="0"
          :defaultColDef="defaultColDef"
          suppressCellFocus
          :rowSelection="'multiple'"
          :rowMultiSelectWithClick="'true'"
          @rowSelected="onRowSelected"
          @rowDoubleClicked="onRowDoubleClicked"
        ></AgGridVue>
        <ReiseterminAnlegenModal @reiseterminAnlegen="gridApi.onFilterChanged()"></ReiseterminAnlegenModal>
        <MultipleReiseterminBearbeitenModal
          :title="'Daten der Reise ' + reiseOfSelectedRows + ' bearbeiten'"
          :reiseterminEditData="multipleReiseterminEditData"
          @reiseterminValuesBearbeitet="onReiseterminValuesBearbeitetMultiple"
          @reiseterminMetadataBearbeitet="onReiseterminMetadataBearbeitetMultiple"
        ></MultipleReiseterminBearbeitenModal>
        <TerminplanungSidebar
          @statusBearbeiten="onStatusSidebarBearbeiten"
          :reiseterminId="activeReisetermin?.id"
          @sidebarClosed="onSidebarClosed"
          @rowSelectionChanged="changeRowSelection"
          @reiseterminMetadataBearbeitet="onReiseterminMetadataBearbeitet"
          @reiseterminBearbeitet="onReiseterminBearbeitet"
          @inlandsflugBearbeitet="onInlandsflugBearbeitet"
          @showModalAufgabeEdit="onShowModalAufgabeEdit"
          @deletedInlandsflug="onDeletedInlandsflug"
          @createdInlandsflug="onCreatedInlandsflug"
          @reiseterminLoeschen="onDeleteReisetermin"
        ></TerminplanungSidebar>
        <ModalStatusAendern
          :reiseterminIds="idsSelectedReisetermine"
          :reiseterminState="stateSelectedReisetermine"
          @statusAendern="onStatusAendern"
        ></ModalStatusAendern>
        <AufgabeErstellenModal
          :prefixedVerlinkungen="prefixedVerlinkungen"
          :disableAttachments="false"
          :fromFVC="false"
          :enableManualTask="true"
        />
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import FilterOverview from '@/components/common/filter-overview.vue';
import apiService from '@/core/common/services/api.service';
import buildQuery from 'odata-query';
import CustomTooltip from '@/components/flugverfuegbarkeit/custom-tooltip.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import {
  fernRegions,
  euRegions,
  dachRegions,
  formatDateWithoutHours,
  getBlankSyncState,
  reiseterminStateFormatter,
} from '@/core/common/helpers/utils.js';
import {
  terminplanungHeaderRow,
  terminplanungSelectedReiseterminVariables,
  terminplanungExpandConfig,
} from '@/core/common/helpers/exportHelper.js';
import AktionenRenderer from '@/components/produkte/reisetermine/aktionen-renderer.vue';
import ReiseterminAnlegenModal from '@/components/produkte/reisetermine/reisetermin-anlegen-modal.vue';
import MultipleReiseterminBearbeitenModal from '@/components/produkte/reisetermine/multiple-reisetermin-bearbeiten-modal.vue';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import { utils, writeFileXLSX } from 'xlsx';
import { add, parseISO, format } from 'date-fns';
import { mapGetters } from 'vuex';
import TerminplanungSidebar from '@/components/produkte/reisetermine/sidebar-terminplanung.vue';
import ModalStatusAendern from '@/components/produkte/reisetermine/modal-status-aendern.vue';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import * as odataService from '@/core/common/services/odata.service';
import RegionFilterButtonGroup from '@/components/common/region-filter-button-group.vue';
import RangeNumberFilterCustomHeader from '@/components/flugverfuegbarkeit/custom-header-range-number-filter.vue';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';

export default {
  name: 'ReisenPlanningComponent',
  components: {
    AgGridVue,
    FilterOverview,
    CustomTooltip,
    agColumnHeader: CustomHeader,
    AgDateRangePicker,
    StatusBarComponent,
    AktionenRenderer,
    ReiseterminAnlegenModal,
    TerminplanungSidebar,
    MultipleReiseterminBearbeitenModal,
    ModalStatusAendern,
    AufgabeErstellenModal,
    RegionFilterButtonGroup,
    RangeNumberFilterCustomHeader: RangeNumberFilterCustomHeader,
    CellRendererPax,
  },
  props: {
    tableHeight: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      lastOdataFilter: null,
      gridApi: null,
      gridColumnApi: null,
      prefixedVerlinkungen: [],
      isFilterSupplied: false,
      columnDefs: null,
      vergangeneTermineAusblenden: true,
      defaultColDef: {
        floatingFilter: true,
        filter: true,
        flex: 1,
        resizable: false,
        sortable: false,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
        tooltipComponent: 'CustomTooltip',
      },
      selectedRegions: [],
      regionFilterDisabled: true,
      allSelectedRows: [],
      count: null,
      statusBar: null,
      reiseterminEditData: null,
      activeReisetermin: null,
      selectedRowID: null,
      odataFilterReiseterminkuerzel: null,
    };
  },
  watch: {
    // schaut auf das array "selectedRegions" und dessen veränderung
    selectedRegions(newSelectedRegions) {
      // und geben das newSelectedRegions aus dem emit in die methode
      this.changeRegionFilterInstance(newSelectedRegions);
    },
  },
  created() {
    this.columnDefs = [
      {
        field: 'istZusatztermin',
        cellRenderer: params => {
          if (params.value) {
            return 'Z';
          }
          return '';
        },
        filterParams: {
          values: [true, false],
          valueFormatter: params =>
            params.value === true
              ? 'Zusatztermin'
              : params.value === false
              ? 'Kein Zusatztermin'
              : params.value,
        },
        headerName: '',
        pinned: 'left',
        width: 50,
        minWidth: 50,
      },
      {
        field: 'reiseterminkuerzel',
        headerName: 'Reise',
        filter: 'agSetColumnFilter',
        filterParams: {
          refreshValuesOnOpen: true,
          values: params => params.success(this.reisenOptions),
          debounceMs: 1000,
        },
        valueFormatter: params => params.value.substring(0, 6),
        checkboxSelection: true,
        headerCheckboxSelection: true,
        sortable: true,
        pinned: 'left',
        minWidth: 150,
        width: 150,
      },
      {
        field: 'state',
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        sortable: true,
        filterParams: {
          valueFormatter: params => reiseterminStateFormatter[params.value],
          values: Object.keys(reiseterminStateFormatter),
          debounceMs: 1000,
        },
        valueFormatter: params => reiseterminStateFormatter[params.value],
        minWidth: 150,
        width: 150,
      },
      {
        field: 'reiseterminSyncState',
        headerName: 'In Blank angelegt',
        cellRenderer: params => {
          const { value } = params;
          const label = getBlankSyncState(value);
          if (label === 'Angelegt') {
            return "<i class='fas fa-check text-success fa-lg'/>";
          } else if (label === 'Nicht synchronisiert') {
            return '-';
          }
          return "<i class='ml-1 fas fa-times text-danger fa-lg'/>";
        },
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: [true, false, null],
          cellRenderer: params => {
            if (params.value === '(Select All)') {
              return params.value;
            } else if (params.value) {
              return "<span class='d-flex align-items-center'><i class='fas fa-check text-success fa-lg'/></span>";
            } else if (params.value === false) {
              return "<span class='d-flex align-items-center'><i class='fas fa-times text-danger fa-lg'/></span>";
            }
            return '-';
          },
          debounceMs: 1000,
        },
        width: 135,
        minWidth: 135,
      },
      {
        field: 'abreisedatum',
        headerName: 'Abreise',
        sortable: true,
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        pinned: 'left',
        minWidth: 180,
        width: 180,
      },
      {
        field: 'verkehrstagHin',
        headerName: 'VT Hin',
        pinned: 'left',
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 1000,
          values: [1, 2, 3, 4, 5, 6, 7],
        },
        width: 80,
        minWidth: 80,
      },
      {
        field: 'startdatum',
        headerName: 'Startdatum',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        sortable: true,
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        minWidth: 180,
        width: 180,
      },
      {
        field: 'metadata.hotelnaechte',
        headerName: 'Hotelnächte',
        minWidth: 120,
        width: 120,
        filter: false,
      },
      {
        field: 'reisedauer',
        headerName: 'Reisedauer',
        floatingFilter: false,
        minWidth: 120,
        width: 120,
      },
      {
        field: 'thLangstrecke',
        headerName: 'TH Langstrecke',
        cellRenderer: params => (params.value === null ? '-' : params.value + '€'),
        floatingFilter: false,
        minWidth: 150,
        width: 150,
      },
      {
        field: 'thInlandsflug',
        headerName: 'TH Inlandsflug',
        cellRenderer: params => (params.value === null ? '-' : params.value + '€'),
        floatingFilter: false,
        minWidth: 150,
        width: 150,
      },
      {
        field: 'metadata.overnightOutbound',
        headerName: 'OB',
        headerTooltip: 'Overnight Outbound',
        floatingFilter: false,
        cellRenderer: params => (params.value === null ? '-' : params.value),
        minWidth: 100,
        width: 100,
      },
      {
        field: 'metadata.overnightInbound',
        headerName: 'IB',
        headerTooltip: 'Overnight Inbound',
        floatingFilter: false,
        cellRenderer: params => (params.value === null ? '-' : params.value),
        minWidth: 100,
        width: 100,
      },
      {
        field: 'flightArrival',
        headerName: 'Flight Arrival',
        valueGetter: params => ({
          abreisedatum: params.data.abreisedatum,
          outbound: params.data.metadata?.overnightOutbound || 0,
        }),
        valueFormatter: params => {
          const { abreisedatum, outbound } = params.value;
          if (abreisedatum) {
            const date = add(parseISO(abreisedatum), { days: outbound });

            return formatDateWithoutHours(date);
          }
          return '-';
        },
        filter: false,
        minWidth: 130,
        width: 130,
      },
      {
        field: 'ziellandFlughaefen',
        headerName: 'Strecke',
        headerTooltip: 'Zielland Zielflughäfen - Zielland Abflughäfen',
        cellRenderer: params => {
          const ziellandZielflughaefen = params.data.metadata.ziellandZielflughaefen
            .map(item => item.flughafenIataCode)
            .join(', ');
          const ziellandAbflughaefen = params.data.metadata.ziellandAbflughaefen
            .map(item => item.flughafenIataCode)
            .join(', ');
          return `${ziellandZielflughaefen} - ${ziellandAbflughaefen}`;
        },
        floatingFilter: false,
        minWidth: 120,
        width: 120,
      },
      {
        field: 'hotelendUndRueckflugdatum',
        headerName: 'Rückflugdatum',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        minWidth: 180,
        width: 180,
      },
      {
        field: 'verkehrstagRueck',
        headerName: 'VT Rück',
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 1000,
          values: [1, 2, 3, 4, 5, 6, 7],
        },
        width: 80,
        minWidth: 80,
      },
      {
        field: 'enddatum',
        headerName: 'Ankunft DE',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        minWidth: 180,
        width: 180,
        sortable: true,
      },
      {
        field: 'pax.ist',
        headerName: 'PAX',
        headerComponent: 'RangeNumberFilterCustomHeader',
        cellRenderer: 'CellRendererPax',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['apply'],
        },
        sortable: false,
        minWidth: 165,
        width: 165,
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'justify-items': 'center',
          overflow: 'visible',
          'align-items': 'center',
          'padding-bottom': '10px',
        },
        cellRendererParams: params => ({
          paxObject: params.data.pax,
          minPax: params.data.metadata.minPax,
          maxPax: params.data.metadata.maxPax,
          kalkPax: params.data.metadata.kalkPax,
          erwartetePax: params.data.metadata.erwartetePax,
          durchfuehrbareMinPax: params.data.metadata.durchfuehrbareMinPax,
          id: params.data.id,
        }),
      },
      {
        field: 'metadata.erwarteteTermindurchfuehrungsquote',
        headerName: 'Erw. Termindurchführungsquote',
        cellRenderer: params => (params.value ? params.value + '%' : '-'),
        filter: false,
        sortable: false,
        minWidth: 250,
        width: 250,
      },
      {
        field: 'reise.region',
        headerName: 'Region',
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['FERN', 'EU', 'DACH'],
        },
      },
    ];

    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
  },
  computed: {
    ...mapGetters(['getReisekuerzelOptions']),
    onlyOneReiseSelected() {
      return (
        this.allSelectedRows.length > 1 &&
        new Set(this.allSelectedRows.map(row => row.data.reiseterminkuerzel.substring(0, 6))).size === 1
      );
    },
    multipleReiseterminEditData() {
      if (this.allSelectedRows.length > 1) {
        return this.allSelectedRows.map(row => row.data);
      } else return [];
    },
    reisenOptions() {
      return this.getReisekuerzelOptions.map(r => r.reisekuerzel);
    },
    reiseOfSelectedRows() {
      if (
        this.allSelectedRows.length > 1 &&
        new Set(this.allSelectedRows.map(row => row.data.reiseterminkuerzel.substring(0, 6))).size === 1
      ) {
        return this.allSelectedRows[0].data.reiseterminkuerzel.substring(0, 6);
      }
      return '';
    },
    idsSelectedReisetermine() {
      if (this.allSelectedRows.length > 0) {
        return this.allSelectedRows.map(item => item.data.id);
      } else return [];
    },
    isOnlyOneReiseterminStateSelected() {
      return new Set(this.allSelectedRows.map(row => row.data.state)).size === 1;
    },
    stateSelectedReisetermine() {
      if (this.isOnlyOneReiseterminStateSelected) {
        return this.allSelectedRows[0].data.state;
      } else return '';
    },
  },
  mounted() {},
  methods: {
    getBlankSyncState,
    onSidebarClosed() {
      this.activeReisetermin = null;
      this.gridApi.deselectAll();
    },
    onShowModalAufgabeEdit(prefixedVerlinkungen) {
      this.prefixedVerlinkungen = prefixedVerlinkungen;
      this.$bvModal.show('modalAufgabeEdit');
    },
    openAufgabenModalWithData() {
      this.prefixedVerlinkungen = this.allSelectedRows.map((item, i) => ({
        id: i,
        typ: 'Reisetermin',
        artifactId: item.data.id,
        artifactText: item.data.reiseterminkuerzel,
      }));

      this.$bvModal.show('modalAufgabeEdit');
    },
    onReload() {
      this.gridApi.refreshServerSide({ purge: true });
    },
    changeRowSelection(direction) {
      const displayedRows = this.gridApi?.getDisplayedRowCount();
      if (direction === 'up') {
        if (this.selectedRowID !== 0) {
          const targetedRowID = this.selectedRowID - 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.activeReisetermin = rowNode.data;
              this.selectedRowID = targetedRowID;
            }
          });
        }
      } else if (direction === 'down') {
        if (this.selectedRowID + 1 < displayedRows) {
          const targetedRowID = this.selectedRowID + 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.activeReisetermin = rowNode.data;
              this.selectedRowID = targetedRowID;
            }
          });
        }
      }
    },
    collapseSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-terminplanung');
    },

    getFlughaefenString(metadata) {
      const ziellandZielflughaefen = metadata.ziellandZielflughaefen
        .map(item => item.flughafenIataCode)
        .join(', ');
      const ziellandAbflughaefen = metadata.ziellandAbflughaefen
        .map(item => item.flughafenIataCode)
        .join(', ');
      return `${ziellandZielflughaefen} - ${ziellandAbflughaefen}`;
    },

    getZusatzterminText(istZusatztermin, language) {
      return language === 'de'
        ? istZusatztermin
          ? 'Zusatztermin'
          : 'Kein Zusatztermin'
        : istZusatztermin
        ? 'Additional date'
        : 'No additional date';
    },

    createRowData(rt, language) {
      const { metadata } = rt;
      const flightArrival = new Date(
        add(parseISO(rt.abreisedatum), { days: metadata.overnightOutbound || 0 })
      );
      const isInBlank = getBlankSyncState(rt.reiseterminSyncState) === 'Angelegt';

      return [
        this.getZusatzterminText(rt.istZusatztermin, language),
        rt.reiseterminkuerzel.substring(0, 6),
        new Date(rt.abreisedatum),
        rt.verkehrstagHin ?? '',
        rt.state,
        isInBlank,
        new Date(rt.startdatum),
        metadata.hotelnaechte,
        rt.reisedauer,
        rt.thLangstrecke ? `${rt.thLangstrecke}€` : '',
        rt.thInlandsflug ? `${rt.thInlandsflug}€` : '',
        metadata.overnightOutbound,
        metadata.overnightInbound,
        flightArrival,
        this.getFlughaefenString(metadata),
        new Date(rt.hotelendUndRueckflugdatum),
        rt.verkehrstagRueck ?? '',
        new Date(rt.enddatum), //Ankunft DE
        metadata.erwartetePax,
        metadata.erwarteteTermindurchfuehrungsquote ? `${metadata.erwarteteTermindurchfuehrungsquote}%` : '',
      ];
    },

    createExternRowData(rt) {
      const { metadata } = rt;

      return [
        rt.reiseterminkuerzel.substring(0, 6),
        new Date(rt.abreisedatum),
        metadata.overnightOutbound,
        new Date(rt.startdatum),
        metadata.hotelnaechte,
        new Date(rt.hotelendUndRueckflugdatum),
        metadata.overnightInbound,
        new Date(rt.enddatum),
        rt.state,
      ];
    },

    async fetchReiseTerminData(type = 'standard') {
      return await odataService.getReisetermin({
        filter: this.lastOdataFilter,
        select: terminplanungSelectedReiseterminVariables[type],
        count: true,
        expand: terminplanungExpandConfig[type],
        orderBy: 'startdatum asc',
      });
    },

    createExcelWorkbook(exportData) {
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(exportData);
      utils.book_append_sheet(wb, ws, 'ReiseterminPlanung_Export');
      return wb;
    },
    generateFileName(type = 'standard') {
      const suffix = type === 'extern' ? '_extern' : '';
      return `terminplanung_export${suffix}_${format(new Date(), 'dd-MM-yy_HH:mm')}.xlsx`;
    },

    async exportData(language, type = 'standard') {
      try {
        const exportData = [terminplanungHeaderRow(language, type)];
        const response = await this.fetchReiseTerminData(type);

        response.data.forEach(rt => {
          const rowData = type === 'extern' ? this.createExternRowData(rt) : this.createRowData(rt, language);
          exportData.push(rowData);
        });

        const wb = this.createExcelWorkbook(exportData);
        writeFileXLSX(wb, this.generateFileName(type));
      } catch (error) {
        console.error(`Export failed (${type}):`, error);
      }
    },

    async onButtonExport(language) {
      await this.exportData(language, 'standard');
    },

    async onButtonExportExtern(language) {
      await this.exportData(language, 'extern');
    },

    onRowSelected($event) {
      this.allSelectedRows = this.getAllSelectedRows();
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setMarkedCount(this.allSelectedRows.length);
    },
    getAllSelectedRows() {
      const rowsSelected = [];
      this.gridApi.forEachNode((node, index) => {
        if (node.selected) {
          rowsSelected.push(node);
        }
      });
      return rowsSelected;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const statusFilterComponent = this.gridApi.getFilterInstance('state');
      statusFilterComponent.setModel({
        values: ['Vorgeschlagen', 'Aufgelegt', 'Anlagebereit', 'CmBestaetigt', 'CmAngefragt'],
      });

      const defaultSortModel = [
        { colId: 'reise.reisekuerzel', sort: 'asc', sortIndex: 0 },
        { colId: 'startdatum', sort: 'asc', sortIndex: 1 },
      ];

      params.columnApi.applyColumnState({ state: defaultSortModel });

      if (this.$route.query.reisetermine) {
        this.odataFilterReiseterminkuerzel = `reiseterminkuerzel in (${this.$route.query.reisetermine
          .split(',')
          .map(rt => `'${rt}'`)
          .join(',')})`;
      }
      this.gridApi?.onFilterChanged();

      const updateData = () => {
        const server = this.server();
        const datasource = this.createServerSideDatasource(server);
        // register the datasource with the grid
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    createServerSideDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);
          if (response.success) {
            // supply rows for requested block to grid
            params.success({ rowData: response.rows });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
          if (this.$route.query.collapse === 'true') {
            this.triggerSidebarFromControl();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          this.checkIfFilterSupplied();
          const filters = this.getFiltersFromRequest(request);
          const orderBy =
            request.sortModel.length > 0
              ? request.sortModel
                  .map(sortObject => sortObject.colId + ' ' + sortObject.sort)
                  .join(',')
                  .replaceAll('.', '/')
              : ['abreisedatum'];
          this.lastOdataFilter = filters;
          const selectedReiseterminVariables = [...terminplanungSelectedReiseterminVariables.standard, 'id'];

          const expand = {
            metadata: {
              expand: { ziellandAbflughaefen: {}, ziellandZielflughaefen: {} },
            },
            pax: { select: ['min', 'max', 'ist', 'opt', 'anf', 'war', 'vor'] },

            reise: {},
          };

          const odataQuery = buildQuery({
            top: request.endRow - request.startRow,
            skip: request.startRow,
            filter: filters,
            orderBy,
            select: selectedReiseterminVariables,
            count: true,
            expand,
          });
          const response = await apiService.get('/Reisetermin', odataQuery);
          this.count = response.data.result['@odata.count'];
          this.regionFilterDisabled = false;
          const data = response.data.result.value;

          return {
            success: true,
            rows: data,
          };
        },
      };
    },
    getFiltersFromRequest(request) {
      this.gridApi?.deselectAll();
      const { filterModel } = request;
      const filterKeys = Object.keys(filterModel);

      let filters = filterKeys.map(filterKey => {
        const filterValues = filterModel[filterKey];

        const formattedKey = filterKey.replaceAll('.', '/');
        if (filterModel[filterKey].filterType === 'text') {
          if (['id', 'verkehrstagHin', 'pax/ist'].includes(formattedKey)) {
            if (request.filterModel[filterKey].filter === '-') {
              return { [formattedKey]: null };
            } else if (request.filterModel[filterKey].filter.includes('-')) {
              const min = parseInt(request.filterModel[filterKey].filter.split('-')[0]);
              const max = parseInt(request.filterModel[filterKey].filter.split('-')[1]);
              return { [formattedKey]: { le: max, ge: min } };
            } else if (request.filterModel[filterKey].filter[0] === '>') {
              const min = parseInt(request.filterModel[filterKey].filter.split('>')[1]);
              return { [formattedKey]: { gt: min } };
            } else if (request.filterModel[filterKey].filter[0] === '<') {
              const max = parseInt(request.filterModel[filterKey].filter.split('<')[1]);
              return { [formattedKey]: { lt: max } };
            }
            return { [formattedKey]: parseInt(filterValues.filter) };
          }
          return { [formattedKey]: { startswith: filterValues.filter } };
        } else if (filterModel[filterKey].filterType === 'date') {
          const dateFrom = new Date(filterValues.dateFrom.substring(0, 10));
          const dateTo = new Date(filterValues.dateTo.substring(0, 10));
          return {
            [formattedKey]: {
              ge: new Date(dateFrom),
              le: new Date(dateTo),
            },
          };
        } else if (filterModel[filterKey].filterType === 'set') {
          if (formattedKey === 'reiseterminkuerzel') {
            return { 'reise/reisekuerzel': { in: filterModel[filterKey].values } };
          }
          if (formattedKey === 'reiseterminSyncState') {
            const converter = {
              true: "reiseterminSyncState in ('AvailableInBlank','AvailableInBlankAndSyncedHotelleistungWithRelations','AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingente','AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingenteAndZusatzleistungen')",
              false: "reiseterminSyncState in ('NotAvailableInBlank')",
              null: "reiseterminSyncState in ('None')",
            };

            return filterModel[filterKey].values.map(item => converter[item]).join(' or ');
          } else if (formattedKey === 'reise/region') {
            const regionConverter = {
              EU: euRegions.filter(region => region !== 'Dach'),
              FERN: fernRegions,
              DACH: ['Dach'],
            };
            const regionValues = filterValues.values.map(value => regionConverter[value]).flat();
            return { [formattedKey]: { in: regionValues } };
          } else
            return `${formattedKey} in (${filterModel[filterKey].values
              .map(item => `'${item}'`)
              .join(',')}) eq true`;
        }
      });
      filters = [
        ...filters,
        {
          isDeleted: false,
        },
      ];
      if (this.odataFilterReiseterminkuerzel) {
        filters = [...filters, this.odataFilterReiseterminkuerzel];
      }

      if (this.vergangeneTermineAusblenden) {
        filters = [...filters, { abreisedatum: { ge: new Date() } }];
      }

      if (
        !filterKeys.includes('state') &&
        !this.odataFilterReiseterminkuerzel &&
        !filterKeys.includes('reiseterminSyncState') &&
        this.vergangeneTermineAusblenden
      ) {
        filters = [
          ...filters,
          "state in ('Vorgeschlagen','CmBestaetigt','Anlagebereit','Aufgelegt','CmAngefragt') eq true",
        ];
      }

      return filters;
    },

    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    checkIfFilterSupplied() {
      const usedFilters = Object.keys(this.gridApi?.getFilterModel()).filter(item => item !== 'state');
      const statusFilterValues = this.gridApi.getFilterInstance('state')?.appliedModel?.values;
      const defaultStatusFilterDiffers =
        statusFilterValues &&
        (statusFilterValues.length !== 5 ||
          !statusFilterValues.includes('Vorgeschlagen') ||
          !statusFilterValues.includes('Aufgelegt') ||
          !statusFilterValues.includes('Anlagebereit') ||
          !statusFilterValues.includes('CmBestaetigt') ||
          !statusFilterValues.includes('CmAngefragt'));

      this.isFilterSupplied =
        (this.gridApi &&
          (this.regionFilterSelected?.length > 0 ||
            !this.vergangeneTermineAusblenden ||
            this.odataFilterReiseterminkuerzel !== null)) ||
        usedFilters.length > 0 ||
        defaultStatusFilterDiffers;
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      const statusFilterComponent = this.gridApi.getFilterInstance('state');
      statusFilterComponent.setModel({
        values: ['Vorgeschlagen', 'Aufgelegt', 'Anlagebereit', 'CmBestaetigt', 'CmAngefragt'],
      });

      let filterDate1 = this.gridApi.getFilterInstance('abreisedatum');
      let filterDate2 = this.gridApi.getFilterInstance('startdatum');
      let filterDate3 = this.gridApi.getFilterInstance('enddatum');
      this.selectedRegions = [];
      this.vergangeneTermineAusblenden = true;
      this.odataFilterReiseterminkuerzel = null;
      const query = { ...this.$route.query };
      if ('reisetermine' in query) {
        delete query['reisetermine'];
        this.$router.push({ query });
      }
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      filterDate3.onDateRangeFilterChanged(null);
    },
    onReiseterminMetadataBearbeitet(response, fromSidebar = true) {
      const reiseterminkuerzel = Object.keys(response).map(
        key => response[key].reisekuerzel + '_' + response[key].reiseterminStartdatum.substring(0, 10)
      );
      this.updateChangedRow(response, reiseterminkuerzel, fromSidebar);
      this.gridApi.refreshCells({ force: true });
    },
    onInlandsflugBearbeitet(response) {
      const { reiseterminkuerzel, inlandsflug: inlandsflugBearbeitet } = response;
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminkuerzel === rowNode.data.reiseterminkuerzel) {
          const updated = rowNode.data;
          updated.metadata.inlandsfluege = updated.metadata.inlandsfluege.map(inlandsflug => {
            if (inlandsflug.id === inlandsflugBearbeitet.id) {
              return inlandsflugBearbeitet;
            } else return inlandsflug;
          });
          rowNode.updateData(updated);
        }
      });
    },
    onDeletedInlandsflug(response) {
      const { id, reiseterminkuerzel } = response;
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminkuerzel === rowNode.data.reiseterminkuerzel) {
          const updated = rowNode.data;
          updated.metadata.inlandsfluege = updated.metadata.inlandsfluege.filter(
            inlandsflug => inlandsflug.id !== id
          );
          rowNode.updateData(updated);
        }
      });
    },
    onCreatedInlandsflug(response) {
      const { reiseterminkuerzel, newInlandsflug } = response;
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminkuerzel === rowNode.data.reiseterminkuerzel) {
          const updated = rowNode.data;
          updated.metadata.inlandsfluege = [...updated.metadata.inlandsfluege, newInlandsflug];
          rowNode.updateData(updated);
        }
      });
    },
    onReiseterminValuesBearbeitetMultiple() {
      this.gridApi.onFilterChanged();
    },
    onReiseterminMetadataBearbeitetMultiple() {
      this.gridApi.onFilterChanged();
    },
    onReiseterminBearbeitet(reiseterminkuerzel, thLangstrecke, thInlandsflug, istZusatztermin) {
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminkuerzel.includes(rowNode.data.reiseterminkuerzel)) {
          const updated = rowNode.data;
          updated.thLangstrecke = thLangstrecke;
          updated.istZusatztermin = istZusatztermin;
          updated.thInlandsflug = thInlandsflug;
          rowNode.updateData(updated);
        }
      });
    },
    updateChangedRow(response, reiseterminkuerzel, fromSidebar) {
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminkuerzel.includes(rowNode.data.reiseterminkuerzel)) {
          const inlandsfluege = rowNode.data.metadata.inlandsfluege?.slice();
          const { startdatum } = rowNode.data;
          const metadata = response[startdatum];
          const ziellandAbflughaefen = metadata.ziellandAbflughaefenIataCodes.map(iataCode => {
            return {
              flughafenIataCode: iataCode,
              isPrio: iataCode === metadata.ziellandAbflughafenPrio,
            };
          });
          const ziellandZielflughaefen = metadata.ziellandZielflughaefenIataCodes.map(iataCode => {
            return {
              flughafenIataCode: iataCode,
              isPrio: iataCode === metadata.ziellandZielflughafenPrio,
            };
          });
          delete metadata['ziellandAbflughaefenIataCodes'];
          delete metadata['ziellandZielflughaefenIataCodes'];

          const updated = rowNode.data;
          updated.metadata = metadata;
          updated.metadata.restriktionenHinRollover =
            metadata.restriktionenHinRollover == null
              ? metadata.restriktionenHinRollover
              : Boolean(metadata.restriktionenHinRollover);
          updated.metadata.restriktionenRueckRollover =
            metadata.restriktionenRueckRollover == null
              ? metadata.restriktionenRueckRollover
              : Boolean(metadata.restriktionenRueckRollover);
          updated.metadata.inlandsfluege = inlandsfluege;
          updated.metadata.ziellandAbflughaefen = ziellandAbflughaefen;
          updated.metadata.ziellandZielflughaefen = ziellandZielflughaefen;
          rowNode.updateData(updated);
          if (fromSidebar) {
            this.activeReisetermin = rowNode.data;
          }
        }
      });
    },
    onRowDoubleClicked(params) {
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.rowIndex === params.rowIndex) {
          rowNode.setSelected(true);
          this.selectedRowID = params.rowIndex;
        }
      });
      this.activeReisetermin = params.data;
      this.collapseSidebar();
    },
    triggerSidebarFromControl() {
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.rowIndex === 0) {
          rowNode.setSelected(true);
          this.selectedRowID = 0;
          this.activeReisetermin = rowNode.data;
        }
      });
      this.collapseSidebar();
    },
    onReiseterminDeleted() {
      this.gridApi.onFilterChanged();
    },
    onStatusAendern(data) {
      const { kommentar, grund, status } = data;
      if (status === 'Verworfen') {
        apiService
          .post('Reisetermin/VerworfenData', {
            reiseterminIds: this.idsSelectedReisetermine,
            grund,
            kommentar,
          })
          .then(res => {
            this.changeStateReisetermine(this.idsSelectedReisetermine, status);
          });
      } else {
        this.changeStateReisetermine(this.idsSelectedReisetermine, status);
      }
    },
    onDeleteReisetermin() {
      this.hardDeleteReisetermine(this.idsSelectedReisetermine);
    },
    async onClickDeleteReisetermine() {
      const confirmDeletion = await this.$bvModal.msgBoxConfirm(
        'Möchtest du die Reisetermine \n' +
          this.allSelectedRows.map(item => item.data.reiseterminkuerzel).join('\n') +
          ' wirklich löschen?',
        {
          centered: true,
          okTitle: 'Löschen',
          okVariant: 'danger',
          cancelTitle: 'Abbrechen',
        }
      );
      if (confirmDeletion) {
        this.onDeleteReisetermin();
      }
    },
    changeStateReisetermine(reiseterminIds, endState) {
      apiService.put('Reisetermin/ChangeState', { reiseterminIds, endState }).then(res => {
        this.toast('Status erfolgreich bearbeitet.');
        this.gridApi.forEachNode(rowNode => {
          if (reiseterminIds.includes(rowNode.data.id)) {
            const updated = rowNode.data;
            updated.state = endState;
            rowNode.updateData(updated);
          }
        });
        if (this.allSelectedRows?.length > 1) {
          this.allSelectedRows = [];
          this.gridApi.deselectAll();
        }
      });
    },
    hardDeleteReisetermine(reiseterminIds) {
      apiService.delete('Reisetermin/HardDelete', { reiseterminIds }).then(res => {
        this.toast('Erfolgreich gelöscht.');
        this.gridApi.onFilterChanged();
        this.allSelectedRows = [];
      });
    },
    onStatusSidebarBearbeiten(state) {
      this.openModalStatusBearbeiten();
    },
    openModalStatusBearbeiten() {
      this.$root.$emit('bv::toggle::modal', 'modalStatusAendern');
    },
    changeRegionFilterInstance(selectedRegions) {
      if (!this.gridApi) return;

      const regionsFilter = this.gridApi.getFilterInstance('reise.region');
      if (!regionsFilter) return;

      if (selectedRegions.length > 0) {
        regionsFilter.setModel({ values: selectedRegions });
      } else {
        regionsFilter.setModel({});
      }
      this.gridApi.onFilterChanged();
    },
  },
};
</script>

<style scoped>
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}

:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}

:deep(.dots-dropdown) {
  font-weight: 600;
  font-size: 14px;
}

.flagExport:hover {
  opacity: 50%;
}

:deep(.ag-cell),
:deep(.ag-header-cell) {
  padding: 0px 0.5rem;
}
</style>
